<template>
    <div class="pt-16">
        <h3 class="text-gray-700 text-3xl md:text-5xl font-bold mb-8">My Skills</h3>
        <div class="mt-4 flex flex-wrap lg:flex-no-wrap gap-4">
            <div class="p-1 flex flex-col items-center gap-2 hover:scale-105 transition duration-75 delay-75 cursor-pointer">
                <img src="https://seeklogo.com/images/V/vuejs-logo-17D586B587-seeklogo.com.png" class="h-14" alt="VUEJS">
                <span>VueJS</span>
            </div>
            <div class="p-1 flex flex-col items-center gap-2 hover:scale-105 transition duration-75 delay-75 cursor-pointer">
                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Nuxt_logo.svg/512px-Nuxt_logo.svg.png?20201218211241" class="h-14" alt="NUXTJS">
                <span>NuxtJS</span>
            </div>
            <div class="p-1 flex flex-col items-center gap-2 hover:scale-105 transition duration-75 delay-75 cursor-pointer">
                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/JavaScript-logo.png/600px-JavaScript-logo.png?20120221235433" class="h-14" alt="JavaScript">
                <span>JavaScript</span>
            </div>
            <div class="p-1 flex flex-col items-center gap-2 hover:scale-105 transition duration-75 delay-75 cursor-pointer">
                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/Tailwind_CSS_Logo.svg/600px-Tailwind_CSS_Logo.svg.png?20211001194333" class="h-14" alt="Tailwind">
                <span>Tailwind</span>
            </div>
            <div class="p-1 flex flex-col items-center gap-2 hover:scale-105 transition duration-75 delay-75 cursor-pointer">
                <img src="https://seeklogo.com/images/B/bootstrap-logo-3C30FB2A16-seeklogo.com.png" class="h-14" alt="Bootstrap">
                <span>Bootstrap</span>
            </div>
            <div class="p-1 flex flex-col items-center gap-2 hover:scale-105 transition duration-75 delay-75 cursor-pointer">
                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/96/Sass_Logo_Color.svg/1280px-Sass_Logo_Color.svg.png" class="h-14" alt="SASS">
                <span>SASS</span>
            </div>
            <div class="p-1 flex flex-col items-center gap-2 hover:scale-105 transition duration-75 delay-75 cursor-pointer">
                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Laravel.svg/121px-Laravel.svg.png" class="h-14" alt="Laravel">
                <span>Laravel</span>
            </div>
            <div class="p-1 flex flex-col items-center gap-2 hover:scale-105 transition duration-75 delay-75 cursor-pointer">
                <img src="https://cdn-icons-png.flaticon.com/512/5968/5968332.png" class="h-14" alt="PHP">
                <span>PHP</span>
            </div>
            <div class="p-1 flex flex-col items-center gap-2 hover:scale-105 transition duration-75 delay-75 cursor-pointer">
                <img src="https://www.freepnglogos.com/uploads/logo-mysql-png/logo-mysql-mysql-logo-png-images-are-download-crazypng-21.png" class="h-14" alt="MySQL">
                <span>MySQL</span>
            </div>
            <div class="p-1 flex flex-col items-center gap-2 hover:scale-105 transition duration-75 delay-75 cursor-pointer">
                <img src="https://cdn.icon-icons.com/icons2/2699/PNG/512/firebase_logo_icon_171157.png" class="h-14" alt="Firebase">
                <span>Firebase</span>
            </div>
            <div class="p-1 flex flex-col items-center gap-2 hover:scale-105 transition duration-75 delay-75 cursor-pointer">
                <img src="https://seeklogo.com/images/G/github-logo-5F384D0265-seeklogo.com.png" class="h-14" alt="GitHub">
                <span>GitHub</span>
            </div>
            <div class="p-1 flex flex-col items-center gap-2 hover:scale-105 transition duration-75 delay-75 cursor-pointer">
                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/98/WordPress_blue_logo.svg/1024px-WordPress_blue_logo.svg.png" class="h-14" alt="WordPress">
                <span>WordPress</span>
            </div>
            <div class="p-1 flex flex-col items-center gap-2 hover:scale-105 transition duration-75 delay-75 cursor-pointer">
                <img src="https://elementor.com/marketing/wp-content/uploads/2021/10/Elementor-Logo-Symbol-Red.png" class="h-14" alt="Elementor">
                <span>Elementor/Pro</span>
            </div>
            <div class="p-1 flex flex-col items-center gap-2 hover:scale-105 transition duration-75 delay-75 cursor-pointer">
                <img src="https://www.essentialplugin.com/wp-content/uploads/2022/08/Divi-Theme-Logo.png" class="h-14" alt="Divi">
                <span>Divi</span>
            </div>
            <div class="p-1 flex flex-col items-center gap-2 hover:scale-105 transition duration-75 delay-75 cursor-pointer">
                <img src="https://cdn.icon-icons.com/icons2/2699/PNG/512/atlassian_jira_logo_icon_170511.png" class="h-14" alt="Jira">
                <span>Jira</span>
            </div>
            <div class="p-1 flex flex-col items-center gap-2 hover:scale-105 transition duration-75 delay-75 cursor-pointer">
                <img src="https://cdn.sanity.io/images/599r6htc/localized/46a76c802176eb17b04e12108de7e7e0f3736dc6-1024x1024.png?w=670&h=670&q=75&fit=max&auto=format" class="h-14" alt="Figma">
                <span>Figma</span>
            </div>
        </div>
    </div>
</template>